/* eslint jsx-a11y/label-has-for:0 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Layout, Wrapper, Header } from '../components'
import { t, currentLanguage } from '../t/t'

import config from '../../config'

const Content = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem 4rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  margin-top: -3rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  form {
    p {
      label,
      input {
        display: block;
      }
      input {
        min-width: 275px;
        margin-top: 0.5rem;
      }
      textarea {
        resize: vertical;
        min-height: 150px;
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`

const Contact = () => (
  <Layout>
    <Wrapper>
      <Helmet title={`${t[currentLanguage].about} | ${config.siteTitle}`} />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
      </Header>
      <Content>
        <h1>Patryk Szczygło</h1>
        <h3>Programista, bloger, marzyciel</h3>
        <p>
          Jestem prostym i młodym człowiekiem. Stwierdziłem, że poprowadzę tego bloga, więc 15 lutego napisałem pierwszy
          post. Rozpoczęła się moja przygoda.
        </p>
        <p>
          Kolega opowiedział mi o tym konkursie. Wziąłem udział. Doszedłem do końca. Nie zwyciężyłem, nie byłem na gali
          finałowej. Jednak dokonałem wszystkiego co trzeba było osiągnąć. To był początek. Nauczyłem się blogować.
        </p>
        <p>
          Wraz z tym powstała wielka odpowiedzialność. Muszę dobrze pisać. Zacząłem kupować różne książki i się rozwijać
          w tym kierunku. Magia słów, biblia copywritingu. To kolejny krok naprzód.
        </p>
        <p>
          W końcu! Udało mi się zdobyć zdalną pracę. Bez zatrzymania pędzę do przodu. Mimo kilku porażek dziennie w
          ciągu tygodnia zawsze coś się uda. Jedna, może dwie rzeczy. Niewiele, ale nikt nie powiedział, że ma być
          łatwo. Chcesz zrobić rzeczy wielkie, to szykuj się na wiele porażek.
        </p>
        <p>
          Często przytłoczony i załamany. Jednak z prostą myślą, do przodu dla niej, dla siebie, dla lepszego jutra.
          Codziennie coś robić, ale czasami się oderwać od rzeczywistości. Na przykład na desce odjechać od tego świata.
        </p>
        <p>
          Projekt za projektem. Czarodziej JavaScript? Tak. Może jeszcze nie teraz, ale nie czekam i już się tak
          nazywam. Bo będę nim. Ekspert od blockchain? Tak. Samozwańczy. Bo nikt nie nazwał mnie tak, a robię rzeczy o
          których inni nie mają pojęcia i się uczą z moich postów. Blogger? Tak. Piszę już codziennie, bez zatrzymania.
        </p>
        <h3>Kim jestem?</h3>
        <p>
          <b>
            Patryk. Samozwańczy ekspert blockchain. Własny specjalista od JavaScript. Nieznany blogger. Niepowstrzymany
            odkrywca wszystkich światów.{' '}
          </b>
        </p>
        <p>Ogólnie jak to się mówi teraz na ludzi renesansu, murarz tynkarz akrobata.</p>
      </Content>
    </Wrapper>
  </Layout>
)

export default Contact
